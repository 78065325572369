import { jsx } from "react/jsx-runtime";
function withOptionality(WrappedComponent) {
  return function ComponentWithOptionality({
    children,
    enabled,
    ...props
  }) {
    if (!enabled) {
      return children;
    }
    return /* @__PURE__ */ jsx(WrappedComponent, { ...props, children });
  };
}
export {
  withOptionality
};
